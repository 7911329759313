
















import Vue from "vue";
import { AnalyzingFiles } from "@/interfaces/global_valley/analyzing_files";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { GET_UPLOADED_RESUMES_ID } from "@/store/modules/resume_parser/constants";
import router from "@/router";
import { SET_NOTIFICATION } from "@/store";
import {
  GET_JOBS_LISTING,
  GET_POST_JOB_LOADING
} from "@/store/modules/job_parser/constants";

export default Vue.extend({
  name: "AnalyzingFiles",
  data(): AnalyzingFiles {
    return {
      animation: require("@/assets/illustrations/analyzing_files.json"),
      done: false
    };
  },
  async created() {
    this.animation = JSON.stringify(this.animation);
    const obj = {
      page: 0,
      limit: 10
    };
    if (this.post_job_loading) {
      let count = 1;
      while (count < 20) {
        if (this.done) break;
        await this.fetch_posted_jobs(obj);
        count += 1;
        await new Promise((resolve) => setTimeout(resolve, 15000));
      }
    }
  },
  watch: {
    get_uploaded_resumes_ids(new_ids: number[], old_ids: number[]) {
      if (new_ids.length > old_ids.length) {
        router.push({
          name: "global-valley-resume-listing"
        });
        this.set_notification("Resumes Uploaded Successfully");
      }
    },
    async posted_jobs(new_jobs, old_jobs) {
      if (new_jobs.count > 0 && old_jobs.count !== 0) {
        if (new_jobs.count > old_jobs.count) {
          this.done = true;
          this.set_notification("Job Parsed Successfully");
          await router.push({
            name: "global-valley-job-listing"
          });
        }
      } else if (new_jobs.count === 1 && old_jobs.count === 0) {
        this.done = true;
        this.set_notification("Job Parsed Successfully");
        await router.push({
          name: "global-valley-job-listing"
        });
      }
    }
  },
  computed: {
    ...mapGetters("resume_parser", {
      get_uploaded_resumes_ids: GET_UPLOADED_RESUMES_ID
    }),
    ...mapGetters("job_parser", {
      post_job_loading: GET_POST_JOB_LOADING,
      posted_jobs: GET_JOBS_LISTING
    })
  },
  methods: {
    ...mapMutations({
      set_notification: SET_NOTIFICATION
    }),
    ...mapActions("job_parser", {
      fetch_posted_jobs: GET_JOBS_LISTING
    })
  }
});
